<template>
    <div id="clia_chat">
        <div id="conversation-container" class="px-3">
            <div class="pa-3 conversation-item" :class="[message.type == 'user' ? 'justify-end' : 'justify-start']"
                v-for="message in message_history" :key="message.index">
                <UserMessage v-if="message.type == 'user'" :message="message"></UserMessage>
                <BotMessage v-else :message="message"></BotMessage>

            </div>
            <div v-if="$store.state.waiting_response" class="pa-3 conversation-item justify-start">
                <div class="chat-message-item bot-message-item px-5 py-3 rounded-lg elevation-0">
                    <div class="spinner">
                        <div class="bounce1"></div>
                        <div class="bounce2"></div>
                        <div class="bounce3"></div>
                    </div>
                </div>
            </div>
        </div>
        <PromptN8N></PromptN8N>

        <!-- Your component's HTML template goes here -->
    </div>

</template>

<script>
// Import the component(s) you want to use here
import UserMessage from '@/components/UserMessage.vue';
import BotMessage from '@/components/BotMessage.vue';
import PromptN8N from '@/components/PromptN8N.vue';

export default {
    name: 'ChatN8N',
    components: {
        UserMessage,
        BotMessage,
        PromptN8N
    },
    data() {
        return {
            // Your component's data goes here
        };
    },
    computed: {
        message_history() {
            return this.$store.getters.message_history;
        }
    },
    methods: {
        // Your component's methods go here
    },
    mounted() {
        // Code to run when the component is mounted goes here
    },
};
</script>

<style scoped>
#conversation-container {
    min-height: 200px;
    background-color: #e0dede;
}

.conversation-item {
    display: flex;
    flex-direction: row;
}

.spinner {

    width: 70px;
    text-align: center;
}

.spinner>div {
    width: 12px;
    height: 12px;
    background-color: #e60413;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0)
    }

    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}

/* Your component's CSS styles go here */
</style>