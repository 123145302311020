<template>
    <div class="generateur-content">
        <v-container class="mt-5">
            <v-row>
                <v-col cols="12" md="6" class="bg-white rounded elevation-1">
                    <v-form>
                        <v-container class="py-5">
                            <v-row>
                                <v-col cols="12">
                                    <h2 class="text-center">Information du document</h2>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="form_fields['Nom']" label="Nom" required density="compact"
                                        hide-details></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="form_fields['Prénom']" label="Prénom" required
                                        density="compact" hide-details></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="form_fields['Email']" label="Email" required
                                        density="compact" hide-details></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="form_fields['Poste']" label="Poste occupé" required
                                        density="compact" hide-details></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="form_fields['Adresse']" label="Adresse postale" required
                                        density="compact" hide-details></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="form_fields['SIRET']" label="SIRET" required
                                        density="compact" hide-details></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field prepend-icon="mdi-currency-eur" v-model="form_fields['Prix ']"
                                        label="Prix" required density="compact" hide-details></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="form_fields['Object du contrat']" label="Object du contrat"
                                        required density="compact" hide-details></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>


                            </v-row>
                            <v-row v-if="error_message != ''">
                                <v-col>
                                    <v-alert border="start" border-color="#e60413">{{ error_message }}</v-alert>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12" class="text-center">
                                    <v-btn append-icon="mdi-send" @click="submitForm" elevation="0"
                                        :loading="$store.state.waiting_response" class="send-btn-generateur">Générer le
                                        document</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-col>
                <v-col cols="12" md="6" class="py-5">
                    <div v-if="$store.state.generated_documents.length > 0">
                        <h2 class="text-center mb-4">Documents générés</h2>
                        <v-row v-for="(document, index_doc) in $store.state.generated_documents" :key="index_doc">
                            <v-col cols="12">
                                <div class="bg-white rounded elevation-1 pa-3">
                                    <div>{{ document.title }}</div>
                                    <a :href="document.url" target="_blank"><v-btn append-icon="mdi-open-in-new"
                                            class="send-btn-generateur">Ouvrir</v-btn></a>
                                </div>

                            </v-col>
                        </v-row>

                    </div>


                </v-col>
            </v-row>
        </v-container>
        <!-- Your component's HTML template goes here -->

    </div>
</template>

<script>
import moment from 'moment';
export default {
    name: 'GenerateurN8N',
    data() {
        return {
            form_fields: {},
            error_message: '',
            // Your component's data goes here
        };
    },
    methods: {
        async submitForm() {
            this.error_message = '';
            this.$store.state.waiting_response = true;
            // Add the current date to the form fields format DD/MM/YYYY
            this.form_fields.submittedAt = moment().format('DD/MM/YYYY');
            let response_form = await this.$store.dispatch('send_form', this.form_fields);
            if (response_form.status == 'error') {
                this.error_message = response_form.message;
            }
            this.$store.state.waiting_response = false;
            this.resetForm();
            // Code to run when the form is submitted goes here
        },
        // Your component's methods go here
        resetForm() {
            this.form_fields = {
                "Nom": "",
                "Prénom": "",
                "Adresse": "",
                "SIRET": "",
                "Poste": "",
                "Email": "",
                "Object du contrat": "",
                "Prix ": "",
                "submittedAt": "",
                "formMode": "production"
            }
            // Reset the form fields
        },
    },
    mounted() {
        this.resetForm();
        // Code to run when the component is mounted goes here
    },
    // Other component options (e.g., computed properties, lifecycle hooks) go here
};
</script>

<style scoped>
/* Your component's CSS styles go here */
</style>