import { createStore } from 'vuex'
import axios from 'axios';
export default createStore({
  state: {
    webhook_url: "https://chatlegalia.app.n8n.cloud/webhook/1727c687-aed0-49cf-96af-e7796819fbb3/chat",
    webhook_url_form: "https://chatlegalia.app.n8n.cloud/webhook/1a99aa0d-0c90-49eb-8f98-54eb2d337e2c",
    message_history: [],
    waiting_response: false,
    generated_documents: [

    ],
  },
  getters: {
    message_history(state) {
      return state.message_history
    }
  },
  mutations: {
    add_generated_document(state, data) {
      let item = {
        title: data.document_title,
        url: "https://docs.google.com/document/d/" + data.document_id
      }
      state.generated_documents.push(item);
    },
    add_user_message(state, message) {
      let item = {
        type: 'user',
        text: message,
        timestamp: new Date()
      }
      state.message_history.push(item);
    },
    send_bot_welcome_message(state) {
      let item = {
        type: 'bot',
        text: "Bonjour je suis votre assistant juridique IA. Comment puis-je vous aider ?",
        timestamp: new Date()
      }
      if (state.message_history.length === 0) {
        state.message_history.push(item);
      }
    },
    add_bot_message(state, data) {
      let citations = JSON.parse(data.citations);
      let citations_parsed = [];
      if (citations.length > 0) {
        for (let i = 0; i < citations.length; i++) {
          let item = citations[i].split("|");
          citations_parsed.push({
            title: item[0] ? item[0] : "Sans titre",
            url: item[1] ? item[1] : "",
            from: item[2] ? item[2] : "",
            to: item[3] ? item[3] : ""
          })
        }
      }
      state.message_history.push({
        type: 'bot',
        text: data.text,
        citations: citations_parsed,
        timestamp: new Date()
      })
    }
  },
  actions: {
    async send_form({ commit, state }, form) {
      try {
        const response = await axios.post(state.webhook_url_form, [form]);
        commit("add_generated_document", response.data);
        return response.data;
      }
      catch (error) {
        console.error(error);
        return {
          status: "error",
          message: "Désolé, une erreur est survenue. Veuillez réessayer."
        }
      }
    },
    send_bot_welcome_message({ commit }, message) {
      commit('send_bot_welcome_message', message);
    },
    async send_user_message({ commit, state }, message) {
      commit('add_user_message', message);
      let params = {
        "action": "sendMessage",
        "sessionId": "702933b9-6301-4a63-8d45-2b474cb0ddeb",
        "chatInput": message
      }
      try {
        const response = await axios.post(state.webhook_url, params);
        commit('add_bot_message', response.data);
      }
      catch (error) {
        console.error(error);
        commit('add_bot_message', {
          text: "Désolé, une erreur est survenue. Veuillez réessayer."
        });
      }




    }
  },
  modules: {
  }
})
