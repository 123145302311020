<template>
    <div class="home">
        <div id="header">
            <img src="https://chatlegalia.fr/wp-content/uploads/2024/04/logo_cdc_large_cropped.jpg" alt="">
            <div class="titles">
                <h2>
                    Bienvenue sur le générateur de document <br>de la caisse des dépôts
                </h2>
                <h3>POC réalisé par Chat Legal.IA</h3>
            </div>

        </div>
        <GenerateurN8N></GenerateurN8N>
    </div>
</template>

<script>
import GenerateurN8N from '@/components/GenerateurN8N.vue'
export default {
    name: 'DocumentGeneratorView',
    components: {
        GenerateurN8N
    },
    data() {
        return {
            // Your component's data goes here
        };
    },
    methods: {
        // Your component's methods go here
    },
    mounted() {
        // Code to run when the component is mounted goes here
    },
    // Other component options (e.g., computed properties, lifecycle hooks) go here
};
</script>

<style scoped>
/* Your component's CSS styles go here */
</style>