<template>
    <div class="chat-message-item bot-message-item px-5 py-3 rounded-lg elevation-0">

        <div>{{ message.text }}</div>
        <div class="mt-2" v-if="message.citations && message.citations.length > 0"><i>Sources:</i></div>
        <div v-for="(citation, index_citation) in message.citations" :key="index_citation">
            <div>- Lignes {{ citation.from }} à {{ citation.to }} du document <a :href="citation.url" target="_blank">{{
                citation.title
                    }}</a></div>

        </div>
        <!-- Your component's HTML template goes here -->
    </div>
</template>

<script>
export default {
    props: ['message'],
    name: 'BotMessage',
    // Your component's JavaScript logic goes here
}
</script>

<style scoped>
.bot-message-item {
    max-width: 80%;

}

/* Your component's CSS styles go here */
</style>