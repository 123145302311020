<template>
    <div id="prompt-bar">
        <!-- Your component's HTML template goes here -->
        <v-text-field :disabled="$store.state.waiting_response" class="rounded-0" label="Votre question"
            variant="outlined" hide-details v-model="prompt"></v-text-field>
        <v-btn class="send_btn" @click="send" :disabled="$store.state.waiting_response">Envoyer</v-btn>
    </div>
</template>

<script>
export default {
    name: 'PromptN8N',
    data() {
        return {
            prompt: ''
            // Your component's data goes here
        };
    },
    methods: {
        async send() {
            if (this.prompt === '') return;
            this.$store.state.waiting_response = true;

            await this.$store.dispatch('send_user_message', this.prompt);
            this.prompt = '';
            this.$store.state.waiting_response = false;

        }
        // Your component's methods go here
    },
    mounted() {
        // Code to run when the component is mounted goes here
    },
    // Other component options (e.g., computed properties, lifecycle hooks) go here
};
</script>

<style scoped>
/* Your component's CSS styles go here */
</style>