<template>
    <div class="chat-message-item user-message-item px-5 py-3 rounded-lg elevation-0">
        <div>{{ message.text }}</div>
        <!-- Your component's HTML template goes here -->
    </div>
</template>

<script>

export default {
    props: ['message'],
    name: 'UserMessage',
    // Your component's JavaScript logic goes here
}
</script>

<style scoped>
.user-message-item {
    border: 1px solid #ccc;
    max-width: 80%;
}

/* Your component's CSS styles go here */
</style>