<template>
  <div class="home">
    <div id="header">
      <img src="https://chatlegalia.fr/wp-content/uploads/2024/04/logo_cdc_large_cropped.jpg" alt="">
      <div class="titles">
        <h2>
          Bienvenue sur la plateforme IA de la caisse des dépôts
        </h2>
        <h3>POC réalisé par Chat Legal.IA</h3>
      </div>

    </div>
    <ChatN8N></ChatN8N>
  </div>
</template>

<script>
import ChatN8N from '@/components/ChatN8N.vue'
// @ is an alias to /src


export default {
  name: 'HomeView',
  components: {
    ChatN8N
  },
  mounted() {
    this.$store.dispatch('send_bot_welcome_message');

  }
}
</script>

<style></style>
