<template>

  <router-view />
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
  background-color: #e0dede;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

#header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  gap: 20px;
  flex-wrap: wrap;
  background-color: #e60413;

}

#header img {
  width: 100px;
  height: 100px;
}

#header .titles {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

}

#header h2 {
  color: white;
  text-transform: uppercase;
  font-size: 2rem;
  margin: 0;
}

#header h3 {
  color: white;
  font-size: 1.5rem;
  margin: 0;
}

.chat-message-item {
  font-weight: 500;
}

.bot-message-item {

  background-color: #f2f4f8;
}

.user-message-item {
  background-color: #e60413;
  color: white;
}

.send_btn {
  background-color: #e60413 !important;
  color: white !important;
  font-weight: 500 !important;
  height: 56px !important;
}

.send-btn-generateur {
  background-color: #e60413 !important;
  color: white !important;
  font-weight: 500 !important;

}

#prompt-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f2f4f8;
  gap: 20px;
}

.form-container {}

.generateur-content {
  background-color: #e0dede !important;
}
</style>
